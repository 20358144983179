.hiddenName {
  opacity: 0;
  filter: blur(5px);
  transition: 1s all;
}

@media (prefers-reduced-motion) {
  .hiddenName {
    transition: none;
  }
}

.show {
  opacity: 1;
  filter: blur(0);
}
